<template>
    <div class="p-grid crud-demo">
        <div class="p-col-12">
            <div class="card">
                <Toast/>
                <Toolbar class="p-mb-4">
                    <template v-slot:left>
                        <Button label="Ajouter une entreprise" icon="pi pi-plus" class="p-button  p-button-raised p-component p-button-success p-mr-2" @click="openNew" />
                    </template>
                </Toolbar>
                <DataTable ref="dt" :value="items" v-model:selection="selectedItems" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                            <h5 class="p-m-0">Entreprises</h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                    <InputText @keyup="search()" v-model="filters" placeholder="Siren..."/>
                            </span>
                        </div>
                    </template>
                    <Column field="siren" header="Siren">
                        <template #body="slotProps">
                            <span class="p-column-title">Siren</span>
                            {{slotProps.data.siren}}
                        </template>
                    </Column>
                    <Column field="name" header="Nom">
                        <template #body="slotProps">
                            <span class="p-column-title">Nom</span>
                            {{slotProps.data.name}}
                        </template>
                    </Column>
                    <Column field="status" header="Status" >
                        <template #body="slotProps">
                            <span class="p-column-title">Status</span>
                            {{slotProps.data.status}}
                        </template>
                    </Column>
                       <Column>
                        <template #body="slotProps">
                           <router-link class="detailCompany" :to="{ name: 'details', props:true, params: {id: slotProps.data.id}}"><Button icon="fas fa-eye" class="p-button  p-button-raised p-component p-button-warning p-mr-2" /></router-link>                      
                        </template>
                    </Column>
                </DataTable>
                  <Dialog v-model:visible="addCompany" :style="{width: '600px'}"  header='+ Nouvelle entreprise' :modal="true" class="p-fluid">
                        <form  name="company" id="company-form"  @submit.prevent="saveItem(!v$.$invalid)" novalidate>
                            <div class="p-formgrid p-grid " style="margin-bottom: 20px;">
                                <div class="p-fied p-col">
                                    <label for="siren">Siren<strong>*</strong></label><br>
                                    <InputText  class="mr-3" name="siren" v-model="item.siren" id="siren" autofocus="true" required="true" :class="{'p-invalid':v$.item.siren.$invalid && isSubmitted}"/>
                                       <ul v-if="(v$.item.siren.$invalid && isSubmitted) || v$.item.siren.$pending.$response" class="p-error">
                                            <li v-if="v$.item.siren.required.$invalid">Veuillez renseigner ce champ.</li>
                                            <li v-if="!v$.item.siren.minLength.$invalid">Le Siren doit faire {{v$.item.siren.minLength.$params.min}} caracteres.</li>
                                        </ul>
                                </div>
                                <div class="p-fied p-col">
                                    <label for="nom">Nom<strong>*</strong></label><br>
                                    <InputText class="mr-3" name="nom" id="nom" v-model="item.name" required="true" :class="{'p-invalid':v$.item.name.$invalid && isSubmitted}"/>
                                       <div v-if="(v$.item.name.$invalid && isSubmitted) || v$.item.name.$pending.$response" class="p-error">
                                            <small v-if="v$.item.name.required.$invalid">Veuillez renseigner ce champ.</small>
                                        </div>
                                </div>
                            </div>
                            <div class="p-formgrid p-grid" style="margin-bottom: 20px">
                                <div class="p-fied p-col">
                                    <label>Status<strong>*</strong></label>
                                    <Dropdown v-model="item.status" :options="status" optionLabel="name" optionValue="code" :class="{'p-invalid':v$.item.status.$invalid && isSubmitted}"  />
                                       <div v-if="(v$.item.status.$invalid && isSubmitted) || v$.item.status.$pending.$response" class="p-error">
                                            <small v-if="v$.item.status.required.$invalid">Veuillez renseigner ce champ.</small>
                                        </div>
                                </div>
                                <div class="p-fied p-col">
                                    <label for="website">Site web</label><br>
                                    <InputText class="mr-3" name="website" id="website" v-model="item.website" placeholder="http://"/>
                                </div>
                            </div>
                            <div class="p-field">
                            <label for="sender-email">E-mail</label>
                                <InputText id="sender-email" v-model.trim="item.email" required="true" autofocus :class="{'p-invalid':v$.item.email.$invalid && isSubmitted}" />
                                <div v-if="(v$.item.email.$invalid && isSubmitted) || v$.item.email.$pending.$response" class="p-error">
                                    <small v-if="v$.item.email.email.$invalid">Adresse Email incorrecte.</small>
                                </div>
                            </div>
                        </form>
                        <template #footer>
                            <Button label="Annuler"  icon="pi pi-times" class=" p-button-danger p-button-text" @click="hideDialog"/>
                            <Button label="Ajouter" icon="pi pi-plus" class=" p-button-success " type="submit" form="company-form"/> 
                        </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, minLength, email} from '@vuelidate/validators'
//import axios from 'axios'


export default {
    data() {
        return {
            items: [],
            addCompany: false,
            selectedItems: null,
            filters: null,
            isSubmitted: false,
            currentPage:1,
            item:{},
            status:[
                {name:'Client', code:'Client'},
                {name:'Prospect',code:'Prospect'},
            ]
        }
    },
    validations() {
        return { 
            item: {
                siren:{
                    required, 
                    minLength: minLength(9)
                    },
                name:{required},
                status:{required},
                email:{
                    email
                },
            }
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    created() {
        this.reloadData();
    },
    methods: {
        reloadData() {
            this.$resources.companies.list().then(response => this.items = response.data);   
             
        },

        search(){
            this.$resources.companies.list(this.currentPage, {siren: this.filters}).then(response => this.items = response.data);  
        },
        openNew() {
            this.addCompany = true;
            this.isSubmitted = false;
        },
        hideDialog() {
            this.addCompany = false;
            this.isSubmitted = false;
           
        },
        saveItem(isValid) {
            this.isSubmitted = true;
            this.item.siren = "" + (this.item.siren);

            if(!isValid) {
                return;
            } else {
                if (this.item.id) {
                    this.$resources.companies.update(this.item.id, this.item).then( (data) => {
                        this.reloadData();
                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Entreprise mise à jour', life: 3000});
                        this.items[this.findIndexById(this.item.id)] = data;
                        this.addCompany = false;
                        this.item = {};
                    });
                } else {
                    this.$resources.companies.create(this.item).then( (data) => {
                        this.reloadData();
                        this.items.push(data);
                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Entreprise ajoutée', life: 3000});

                        this.addCompany = false;
                        this.item = {};
                    });
                } 
            }    
            
        },
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .p-error {
        list-style-type: none;
    }

    .detailCompany{
        text-decoration: none;
    }

    strong {
        color: red;
    }
   
    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;
        .p-button {
            margin-bottom: .25rem;
        }
    }
</style>
